.numeric-keypad {
    left: 0;
    top: calc(100px + 15vmin);
    height: calc(100% - 160px - 15vmin);
    position: absolute;
    width: 100%;
}

.numeric-keypad .numbers .cell {
    width: calc(33.3% - 2px);
    height: calc(25% - 2px);
}

.numeric-keypad .icon-backspace, .numeric-keypad .icon-check{
    width: calc(10px + 5vmin);
}

.schulte-table {
    left: 0;
    top: 80px;
    height: calc(100% - 200px);
    position: absolute;
    width: 100%;
}

.cursor {
    height: 50px;
    margin: 5px;
    font-size: calc(10px + 3vmin);
}

.time {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: calc(10px + 3vmin);
    font-family: serif;
}

.characters, .numbers {
    height: 100%;
    margin: 5px;
}

.characters .cell, .numbers .cell {
    border: 1px solid #ccc;
    display: inline-block;
    position: relative;
    transition-duration: 0.4s;
    margin-top: -6px;
    font-size: calc(140% + 5vmin);
    font-weight: bold;
}

.characters.size-9 .cell {
    width: calc(33.3% - 2px);
    height: calc(33.3% - 2px);
}

.characters.size-16 .cell {
    width: calc(25% - 2px);
    height: calc(25% - 2px);
}

.characters.size-25 .cell {
    width: calc(20% - 2px);
    height: calc(20% - 2px);
}

.characters.size-36 .cell {
    width: calc(16.6% - 2px);
    height: calc(16.6% - 2px);
}

.characters.size-49 .cell {
    width: calc(14.2% - 2px);
    height: calc(14.2% - 2px);
    font-size: calc(120% + 3vmin);
}

.characters.size-64 .cell {
    width: calc(12.5% - 2px);
    height: calc(12.5% - 2px);
    font-size: calc(100% + 3vmin);
}

.characters.size-81 .cell {
    width: calc(11.1% - 2px);
    height: calc(11.1% - 2px);
    font-size: calc(100% + 2vmin);
}

.characters .cell span, .numbers .cell span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.characters .cell.correct {
    background-color: #5DB75D !important;
}

.characters .cell.incorrect {
    background-color: #D9544E !important;
}

.characters .cell:hover, .numbers .cell:hover {
    background-color: #048CBA;
}

@media (hover: none) {
    .characters .cell:hover, .numbers .cell:hover {
        background-color: inherit;
    }
}
